<template>
    <a-card>
        <a-row>
            <a-col :span="5">
                <a-row style="margin-top: 5px">
                    <a-col :span="2">
                        <a-icon type="share-alt" />
                    </a-col>
                    <a-col :span="7">{{l('organization')}}</a-col>
                    <a-col :span="15">
                        <a-icon type="reload" @click="getTree" style="float: right" />
                    </a-col>
                </a-row>

                <a-row>
                    <div style="width:100%;overflow:auto;" :style="styObj">
                        <div class="con" style="width: 100%;min-width: 180px;">
                            <a-spin :spinning="isTreeLoading">
                                <a-tree style="width:100%" :expanded-keys="expandedTreeKeys"
                                    :selected-keys="selectedTreeKeys" v-model="checkedTreeKeys" :tree-data="treeData"
                                    :replace-fields="treeReplaceFields" @select="onTreeSelect" @expand="onTreeExpand" />
                            </a-spin>
                        </div>
                    </div>
                </a-row>
            </a-col>

            <a-col :span="19" style="padding-left: 10px;">
                <a-row style="margin-bottom: 20px;">
                    <a-col :span="24" class="d-flex justify-content-end">
                        <a-range-picker showTime :ranges="{ 现在: [moment(), moment()] }"
                            :placeholder="[l('WEStartDate'), l('WEEndDate')]" format="YYYY-MM-DD HH:mm:ss"
                            style="text-align: center;width: 350px;margin-right: 10px" @change="timechange" />
                        <a-tree-select style="width: 350px;margin-right: 10px;" :tree-data="workProcessData"
                            :replace-fields="treeReplace" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :placeholder="l('220')" allow-clear multiple tree-default-expand-all
                            @change="workProcessClassChange">
                        </a-tree-select>
                        <a-select showSearch :filterOption="filterOption"
                            style="width: 150px;text-align: left;margin-right: 10px;"
                            dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                            :placeholder="l('Please_select_a_label')" @change="AuditTaskLabelChange" allow-clear>
                            <a-select-option v-for="item in AuditTaskLabelList" :key="item.itemDetailCode"
                                :title="item.itemDetailName">
                                {{ item.itemDetailName }}
                            </a-select-option>
                        </a-select>

                        <a-input-number v-model="startNum" style="width: 150px;text-align: left;margin-right: 10px;"></a-input-number>
                        <a-input-number v-model="endNum" style="width: 150px;text-align: left;margin-right: 10px;"></a-input-number>
                        <a-button type="primary" @click="getData()" style="margin-right: 10px;">
                            <a-icon type="search" />
                            {{l('Search')}}
                        </a-button>

                        <a-button type="primary" @click="handleMenuClick" v-if="isGranted('user_audit_record_export')">
                            <a-icon type="import"/>
                            {{l('export')}}
                        </a-button>
                    </a-col>
                </a-row>
                <a-spin :spinning="isLoading">
                    <a-table :columns="columns" size="small" :data-source="tableData" :customRow="customRow"
                        :rowKey="(record)=>record.id" :pagination="false" :scroll="{x:1000,y:600}">
                    </a-table>
                </a-spin>
                <a-config-provider :locale="zh_CN">
                    <a-pagination class="pagination" v-model="pageNumber" :total="totalItems" showSizeChanger
                        size="small" :defaultPageSize="request.maxResultCount" :pageSizeOptions="pageSizeOptions"
                        :showTotal="() => showTotal" @change="onPageChange" @showSizeChange="showPageSizeChange" />
                </a-config-provider>

            </a-col>
        </a-row>
    </a-card>
</template>




<script>
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import AppComponentBase from '../../../../shared/component-base/app-component-base.js';
    import { ModalHelper } from "../../../../shared/helpers/modal";
    import { ReportServiceProxy, AuditPointServiceProxy, LayoutOrgServiceProxy } from "../../../../shared/service-proxies";
    import moment from "moment";
    import { Dic, fileDownloadService } from "../../../../shared/utils";

    let _this;
    export default {
        name: "layoutUser",
        mixins: [AppComponentBase],
        components: {},

        created() {
            _this = this;
            this._LayoutOrgServiceProxy = new LayoutOrgServiceProxy(this.$apiUrl, this.$api);
            this.ReportServiceProxy = new ReportServiceProxy(this.$apiUrl, this.$api);
            this.AuditPointServiceProxy = new AuditPointServiceProxy(this.$apiUrl, this.$api);
            this._fileDownloadService = fileDownloadService;
        },
        async mounted() {
            try {
                this.loading = true;
                this.AuditTaskLabelList = await Dic.getInstance().getDicAsync('AuditTaskLabel');
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }

            this.getTree();
            this.getData();
            this.getWorkProcessData();

            window.addEventListener('resize', this.changeHeight);
            this.changeHeight();
        },

        data() {
            return {
                zh_CN,
                tableData: [],
                treeData: [],
                treeReplaceFields: {
                    title: 'levelNameAndName',
                    key: 'id',
                },
                checkedTreeKeys: [],
                expandedTreeKeys: [],
                selectedTreeKeys: [],
                isTreeLoading: false,
                request: {
                    maxResultCount: 15,
                    skipCount: 0
                },


                isLoading: false,
                isEdit: false,
                filterText: undefined,


                columns: [
                    {
                        title: this.l('Personnel'),
                        width: 200,
                        dataIndex: 'realName',
                        align: 'center',
                    },
                    {
                        title: this.l('Schedule_Audits'),
                        width: 150,
                        dataIndex: 'planAuditCount',
                        align: 'center',
                    },
                    {
                        title: this.l('Number_Of_Audits'),
                        width: 150,
                        dataIndex: 'aclAuditCount',
                        align: 'center',
                        ellipsis: true
                    },
                    {
                        title: this.l('FinishRate'),
                        width: 150,
                        dataIndex: 'completionRate',
                        align: 'center',
                        ellipsis: true
                    },
					{
					    title: this.l('The_number_of_audit_items'),
					    width: 150,
					    dataIndex: 'auditItemCount',
					    align: 'center',
					    ellipsis: true
					},
					{
					    title: this.l('Actual_number_of_audits'),
					    width: 150,
					    dataIndex: 'aclAuditItemCount',
					    align: 'center',
					    ellipsis: true
					},
					{
					    title: this.l('Compliance_number'),
					    width: 150,
					    dataIndex: 'complianceCount',
					    align: 'center',
					    ellipsis: true
					},
					{
					    title: this.l('No_Compliance_number'),
					    width: 150,
					    dataIndex: 'notComplianceCount',
					    align: 'center',
					    ellipsis: true
					},
					{
					    title: this.l('Compliance_rate'),
					    width: 150,
					    dataIndex: 'complianceRate',
					    align: 'center',
					    ellipsis: true
					}
                ],
                selectedRowKey: [], // 选择的数据Id
                selectedRowList: [],
                selectedRow: [],//选择的整行数据

                styObj: {
                    height: 0
                },

                workProcessData: [],
                treeReplace: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                auditTaskLabel: undefined,
                pointId: undefined,
                startTime: undefined,
                endTime: undefined,
                AuditTaskLabelList: [],

                startNum:0,
                endNum:100

            }
        },

        methods: {
            moment,
            //导出
            handleMenuClick() {
                this.ReportServiceProxy.getUserAuditRecordToExcel(
                    this.startTime,
                    this.endTime,
                    this.pointId,
                    this.auditTaskLabel,
                    this.selectedTreeKeys.length > 0 ? this.selectedTreeKeys[0] : undefined,
                    this.startNum,
                    this.endNum,
                    undefined,
                    undefined,
                    this.request.maxResultCount,
                    this.request.skipCount
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this._fileDownloadService.downloadTempFile(res);
                })
            },
            //div高度
            changeHeight() {
                this.styObj.height = window.innerHeight - 199 + 'px';
            },
            //获取数据
            getTree() {
                this.treeData = [];
                this.isTreeLoading = true;
                this._LayoutOrgServiceProxy.getAllData(
                ).finally(() => {
                    this.isTreeLoading = false;
                }).then(rel => {
                    // rel = [];
                    console.log(rel, "树数据");
                    if (rel.length <= 0) {
                        this.treeData = [{ levelNameAndName: this.l('Please_create_an_organization'), id: "0", children: rel, }];
                    }
                    else {
                        this.treeData = [];
                        rel.forEach(item => {
                            this.treeData.push(item)
                        });
                    }
                    // this.expandedTreeKeys.push('null');
                    this.recursion(rel);
                })
            },

            recursion(rel) {
                console.log(rel);
                if (rel.length > 0) {
                    for (let i = 0; i < rel.length; i++) {
                        console.log(rel[i].id)
                        console.log(rel[i].children);
                        this.expandedTreeKeys.push(rel[i].id)
                        // this.recursion(rel[i].children);
                    }
                } else {
                    return
                }
            },
            onTreeExpand(expandedKeys, record) {
                console.log(expandedKeys, "expandedKeys");
                console.log(record, "record");
                this.expandedTreeKeys = expandedKeys;
            },
            //架构选择
            onTreeSelect(selectedKeys, info) {
                console.log(selectedKeys, info);
                if (selectedKeys.length > 0) {
                    this.selectedTreeKeys = [];
                    if (isNaN(selectedKeys[0])) {
                        this.selectedTreeKeys = selectedKeys;

                        this.getData();
                    }
                    else {
                        this.selected = selectedKeys[0];
                        this.selectedTreeKeys = selectedKeys;

                        this.getData();

                    }

                }
            },

            //获取数据
            getData() {
                this.selectedRowKey = [];
                this.selectedRowList = [];
                this.isLoading = true;
                this.ReportServiceProxy.getUserAuditRecord(
                    this.startTime,
                    this.endTime,
                    this.pointId,
                    this.auditTaskLabel,
                    this.selectedTreeKeys.length > 0 ? this.selectedTreeKeys[0] : undefined,
                    this.startNum,
                    this.endNum,
                    undefined,
                    undefined,
                    this.request.maxResultCount,
                    this.request.skipCount
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this.tableData = res.items;
                    this.tableData.map(item => {
                        if (item.planAuditCount <= 0) {
                            item.completionRate = "N/A";
                        }else{
                            item.completionRate = item.completionRate.toFixed(0) + "%";
                        }
                        item.complianceRate = item.complianceRate.toFixed(0) + "%";
                    });
                    this.totalItems = res.totalCount;
                    /*this.totaPages = Math.ceil(
                        res.totalCount / this.request.maxResultCount
                    );
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];*/
                })
            },


            orderby() {
                this.filterText = "name";
                this.getData();
            },

            //行选择
            customRow: (record) => {
                return {
                    on: {
                        click: () => {
                            _this.onSelect(record)
                        }
                    }
                }

            },
            //多选
            SelectAll(record, rows, crows) {
                if (record) {
                    //更改表格数据勾选状态
                    this.tableData.map(item => {
                        item.checked = true;
                    });
                    rows.forEach((item, index) => {
                        //判断是否存在
                        let u = this.selectedRowKeys.find(t => t == item.id);
                        if (!u) {
                            this.selectedRowKeys.push(item.id);
                            this.selectedRowList.push(item);
                        }
                    });
                } else {
                    //更改表格数据勾选状态
                    this.tableData.forEach((item, index) => {
                        item.checked = false;
                        this.selectedRowKeys = _.difference(_this.selectedRowKeys, [item.id]);
                        this.selectedRowList = _.difference(_this.selectedRowList, [item]);
                    });
                }
            },
            // 重写复选框点击事件(不可删除)
            onSelect(record) {
                //debugger;
                record.checked = !record.checked;
                if (record.checked) {
                    this.selectedRowKey.push(record.id);
                    this.selectedRowList.push(record);

                } else {
                    this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
                    this.selectedRowList = _.difference(_this.selectedRowList, [record]);
                }
            },

            //获取审核点
            getWorkProcessData() {
                this.isLoading = true;
                this.AuditPointServiceProxy.getAllData(
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this.workProcessData = JSON.parse(JSON.stringify(res));
                })
            },

            timechange(date, time) {
                if (date.length > 0) {
                    this.startTime = moment(time[0]);
                    this.endTime = moment(time[1]);
                    //await this.getCycle();
                } else {
                    this.startTime = undefined;
                    this.endTime = undefined;
                }
                this.getData();
            },

            workProcessClassChange(key) {
                this.pointId = key;
                this.getData();
            },

            AuditTaskLabelChange(value) {
                this.auditTaskLabel = value;
                this.getData();
            },

            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },

        }


    }



</script>



<style scoped lang="less">
    .pagination {
        margin: 10px auto;
        text-align: right;
    }

    .con {
        padding-bottom: 10px;
    }
</style>